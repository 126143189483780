import { createApp } from 'vue';
import App from '@/app/app.vue';
import router from '@/router';
import { i18nPlugin } from '@/utils/i18n';
import filters from '@/filters';
import MixinComponent from '@/mixin-component';
import { store } from '@/stores/index';
import { mapStores } from 'pinia';
import { useConfigStore } from '@/stores/config';
import { useFogOverlayStore } from '@/stores/fog-overlay';
import { usePopupStore } from '@/stores/popup';
import { useUserStore } from '@/stores/user';
import { usePageStore } from '@/stores/page';
import { useLockedStore } from '@/stores/locked';
import { useMenuStore } from '@/stores/menu';
import { useAssistantStore } from '@/stores/assistant';
import { useProjectStore } from '@/stores/project';
import { useThirdPartyStore } from '@/stores/third-party';
import { useAssignmentStore } from '@/stores/assignment';
import { useApiStore } from '@/stores/api';

// ▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀
// TailwindCSS (atomic classes are not !important)
import '@/styles/main.css';
// then thirdparty styles
import 'tippy.js/dist/tippy.css';

// ▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀
// dayjs locale
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import utc from 'dayjs/plugin/utc';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import isBetween from 'dayjs/plugin/isBetween';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { ScrollWatcher, Tooltip, TextareaAutosize } from '@/directives';
dayjs.locale('fr');
dayjs.extend(utc);
dayjs.extend(duration);
dayjs.extend(relativeTime);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(isBetween);
dayjs.extend(customParseFormat);

function init() {
  // Set side-menu background color default green if production, custom color if not
  document.documentElement.style.setProperty(
    '--menu-color',
    import.meta.env.VITE_ENVIRONMENT === 'production' ? '#004899' : `#${import.meta.env.VITE_BACKGROUND_COLOR || 'ea5297'}`
  );
  // ▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀
  // checking user-agent for all IE
  const isIE = /Trident|MSIE/.test(navigator.userAgent);
  if (isIE) {
    alert('Votre navigateur est trop ancien.');
    return;
  }
  // ▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀
  // watch material-symbols-rounded loading, hiding until it's loaded
  const fonts = { 'material-symbols-rounded': 'Material Symbols Rounded' };
  const style = document.createElement('style');
  style.innerHTML = Object.keys(fonts)
    .map((k) => `.${k} { opacity: 0 !important; max-width: 24px !important; overflow: hidden !important; }`)
    .join('\n');
  document.head.appendChild(style);

  // Safeguard
  setTimeout(() => {
    if (document.head.contains(style)) document.head.removeChild(style);
  }, 15000);

  document.fonts.addEventListener('loadingdone', function(event) {
    Object.entries(fonts).forEach(([key, value]) => {
      if ((event as FontFaceSetLoadEvent).fontfaces.some((fontface) => fontface.family.includes(value))) {
        const reg = new RegExp(`\\.${key}\\s?{.*?}`, 'gs');
        style.innerHTML = style.innerHTML.replaceAll(reg, '');
      }
    });
    if (style.innerHTML.trim().length === 0 && document.head.contains(style)) {
      document.head.removeChild(style);
    }
  });
  // ▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀
  const app = createApp(App)
    .mixin(MixinComponent)
    .directive('scroll-watcher', ScrollWatcher)
    .directive('tooltip', Tooltip)
    .directive('textarea-autosize', TextareaAutosize)
    .use(i18nPlugin)
    .use(store)
    .use(router);
  app.config.globalProperties.$filters = filters;

  // Set stores as global properties to allow every component to use it without having to import in setup
  // Using here instead of mixin to enable use in component data()
  const stores = mapStores(
    useApiStore,
    useConfigStore,
    useFogOverlayStore,
    usePopupStore,
    useUserStore,
    usePageStore,
    useLockedStore,
    useMenuStore,
    useAssistantStore,
    useProjectStore,
    useThirdPartyStore,
    useAssignmentStore
  );
  // will do for us all "app.config.globalProperties.xxxStore = useXXXStore();"
  Object.keys(stores).forEach((k) => (app.config.globalProperties[k] = (stores as any)[k]()));

  app.mount('#root');
}

init();
